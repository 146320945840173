import { generateFailure, generateSuccess, getReportFailure, getReportSuccess } from '@action/report'
import {
  createPageTrackingFailure,
  createPageTrackingSuccess,
  getMentionedInMetaFailure,
  getMentionedInMetaSuccess,
  getMentionedPageFailure,
  getMentionedPageListFailure,
  getMentionedPageListSuccess,
  getMentionedPageSuccess,
  getPageQueryCounterFailure,
  getPageQueryCounterSuccess,
  getPageQueryFailure,
  getPageQuerySucces,
  getPageTrackingDetailFailure,
  getPageTrackingDetailSuccess,
  getPageTrackingListFailure,
  getPageTrackingListSuccess,
  getPageTrackingReportFailure,
  getPageTrackingReportHistoryFailure,
  getPageTrackingReportHistorySuccess,
  getPageTrackingReportMoreFailure,
  getPageTrackingReportMoreSuccess,
  getPageTrackingReportSuccess,
  resetPageQueryCounterFailure,
  resetPageQueryCounterSuccess,
  updatePageTrackingFailure,
  updatePageTrackingSuccess,
} from '@action/site'
import {
  changelog,
  createPageTracking,
  fullReport,
  getPageTracking,
  getPageTrackingDetail,
  getPageTrackingReport,
  getPageTrackingReportDetail,
  getPageTrackingReportHistory,
  getPageTrackingReportMore,
  getReport,
  keywordRanking,
  organicTraffict,
  seoTest,
  topPerformingKeyword,
  updatePageTracking,
} from '@api/report'
import {
  getAllMentioned,
  getMentionedMeta,
  getMentionedPage,
  getMentionedPageQuery,
  getQueryMentioned,
  resetQueryMentioned,
} from '@api/site'
import { REPORT } from '@constant/report'
import { SITE } from '@constant/site'
import site from '@reducer/site'
import callApi from '@saga/api'
import { getMissingKeywordQuerySelector } from '@service/selectors/site/report'
import { data } from 'autoprefixer'
import moment from 'moment'
import { toast } from 'react-toastify'
import { all, call, debounce, fork, put, select, takeLatest, throttle } from 'redux-saga/effects'

function* getMentionedPageSaga({ siteId, page, filter, sort }) {
  let isValidUrl = false
  try {
    const originUrl = new URL(page)
    isValidUrl = originUrl.href
  } catch (e) {
    isValidUrl = false
  }

  let sortField = ''
  switch (sort?.field) {
    case 'QUERY':
      sortField = 'query'
      break
    case 'CLICKS':
      sortField = 'clicks'
      break
    case 'IMPRESSIONS':
      sortField = 'impressions'
      break
    case 'POSITION':
      sortField = 'position'
      break
    case 'CTR':
      sortField = 'ctr'
      break
    case 'MENTIONS':
      sortField = 'mentioned_keyword_count'
      break
  }

  // Convert json to url encode
  const filterState = {
    'position[from]': filter?.filter?.position.from,
    'position[to]': filter?.filter?.position.to,

    'impression[from]': filter?.filter?.impression.from,
    'impression[to]': filter?.filter?.impression.to,

    'mention[from]': filter?.filter?.mention.from,
    'mention[to]': filter?.filter?.mention.to,
    'sort[field]': sortField,
    'sort[type]': sort?.sort,
  }

  const { ok, data: response } = yield call(
    callApi,
    getAllMentioned,
    siteId,
    isValidUrl?.length ? isValidUrl : '',
    filter?.search,
    filter?.page,
    filter?.limit,
    filterState
  )

  if (ok) {
    yield put(getMentionedPageSuccess(siteId, page, response.data))
  } else {
    yield put(getMentionedPageFailure(siteId, page, response.data))
  }
}

function* getPageQuerySaga({ pageId, siteId }) {
  const { ok, data: response } = yield callApi(getMentionedPageQuery, siteId, pageId)

  if (ok) {
    yield put(getPageQuerySucces(pageId, response.data))
  } else {
    yield put(getPageQueryFailure(pageId, response.data))
  }
}

function* handleSeprateQueryRunner(siteId, page, query) {
  const pageCache = `${page}:${query}`
  const [latesCache, latestTimestamp] = yield select(getMissingKeywordQuerySelector(page, query))
  let canFetch = false
  if (latestTimestamp) {
    if (moment().isAfter(latestTimestamp.add(1, 'day'))) {
      canFetch = true
    }
  } else {
    canFetch = true
  }

  if (canFetch) {
    const { ok, data: response } = yield call(callApi, getQueryMentioned, siteId, page, query)
    if (ok) {
      if (response.data.success) {
        yield put(getPageQueryCounterSuccess(response.data.total, pageCache))
      } else {
        yield put(getPageQueryCounterFailure(response.data.total, pageCache))
      }
    } else {
      yield put(getPageQueryCounterFailure(response.data.total, pageCache))
    }
  }
}

function* getQueryCounter({ siteId, page, query }) {
  if (query.length) {
    query = (Array.isArray(query) ? query : [query]).filter(Boolean)
    yield all(query.map(keyQuery => call(handleSeprateQueryRunner, siteId, page, keyQuery)))
  }
}

function* resetQueryCounter({ siteId, page }) {
  const { ok } = yield call(callApi, resetQueryMentioned, siteId, page)
  if (ok) {
    yield toast.success('Sucessfull refresh')
    yield put(resetPageQueryCounterSuccess(page))
    setTimeout(() => {
      window.location.reload()
    }, 200)
  } else {
    yield put(resetPageQueryCounterFailure(page))
    yield toast.error('Failed refresh')
  }
}

function* getMentionedInMetaSaga({ siteId }) {
  const { ok, data: response } = yield call(callApi, getMentionedMeta, siteId)

  if (ok) {
    yield put(getMentionedInMetaSuccess(siteId, response.data))
  } else {
    yield put(getMentionedInMetaFailure(siteId, response.data))
  }
}

function* getListPageSaga({ siteId, page }) {
  const { ok, data: response } = yield call(callApi, getMentionedPage, siteId)

  if (ok) {
    yield put(getMentionedPageListSuccess(siteId, response.data))
  } else {
    yield put(getMentionedPageListFailure(siteId, response.data))
  }
}

function* getReportSaga({ siteId }) {
  const { ok, data } = yield call(callApi, getReport, siteId)
  if (ok) {
    yield put(getReportSuccess(siteId, data))
  } else {
    yield put(getReportFailure(siteId, data))
  }
}

function* generateReportSaga({ siteId, reportType }) {
  let apiModel = null
  switch (reportType) {
    case 'TOP_PERFORMING_KEYWORD':
      apiModel = topPerformingKeyword
      break
    case 'CHANGELOG':
      apiModel = changelog
      break
    case 'SEOTEST':
      apiModel = seoTest
      break
    case 'ORGANIC_TRAFFICT':
      apiModel = organicTraffict
      break
    case 'KEYWORD_RANKING':
      apiModel = keywordRanking
      break
    case 'FULL_REPORT':
      apiModel = fullReport
      break
  }

  const { ok, data } = yield call(callApi, apiModel, siteId)

  if (ok) {
    toast.success('Your report is on generating, we will notify you when finish')
    yield fork(getReportSaga, { siteId })
    yield put(generateSuccess(data))
  } else {
    toast.error('Failed to generate report')
    yield fork(getReportSaga, { siteId })
    yield put(generateFailure(data))
  }
}

function* getPageTrackingSaga({ siteId, search, page, limit, sort }) {
  const sortState = {
    'sort[field]': sort?.field,
    'sort[type]': sort?.type,
  }
  const { ok, data } = yield call(callApi, getPageTracking, siteId, search, page, limit, sortState)
  if (ok) {
    yield put(getPageTrackingListSuccess(siteId, data.data))
  } else {
    yield put(getPageTrackingListFailure(siteId, data.message))
  }
}

function* getPageTrackigDetailSaga({ siteId, pageTrackingId }) {
  const { ok, data } = yield call(callApi, getPageTrackingDetail, siteId, pageTrackingId)
  if (ok) {
    yield put(getPageTrackingDetailSuccess(siteId, pageTrackingId, data.data))
  } else {
    yield put(getPageTrackingDetailFailure(siteId, pageTrackingId, data.data))
  }
}

function* getPageTrackingReportSaga({ siteId, pageTrackingId, reportId }) {
  const { ok, data } = yield call(
    callApi,
    reportId ? getPageTrackingReportDetail : getPageTrackingReport,
    siteId,
    pageTrackingId,
    reportId
  )
  if (ok) {
    yield put(getPageTrackingReportSuccess(siteId, pageTrackingId, data.data))
  } else {
    yield put(getPageTrackingReportFailure(siteId, pageTrackingId, data.data))
  }
}

function* getPageTrackingReportHistorySaga({ siteId, pageTrackingId, reportId }) {
  const { ok, data } = yield call(callApi, getPageTrackingReportHistory, siteId, pageTrackingId, reportId)
  if (ok) {
    yield put(getPageTrackingReportHistorySuccess(siteId, pageTrackingId, reportId, data.data))
  } else {
    yield put(getPageTrackingReportHistoryFailure(siteId, pageTrackingId, reportId, data.data))
  }
}

function* createTrackingSaga({ siteId, data, callback }) {
  const { ok, data: response } = yield call(callApi, createPageTracking, siteId, data)

  if (ok) {
    yield put(createPageTrackingSuccess(siteId, response.data))
    yield toast.success('Page hasbeen added, we will notify you when finish')
    yield fork(getPageTrackingSaga, { siteId })
    yield callback()
  } else {
    yield put(createPageTrackingFailure(siteId, response.data))
    yield toast.error('Failed to add page')
  }
}

function* updateTrackingSaga({ data, pageTrackingId, siteId, callback }) {
  const { ok, data: response } = yield call(callApi, updatePageTracking, siteId, pageTrackingId, data)
  if (ok) {
    yield put(updatePageTrackingSuccess(siteId, response.data))
    yield toast.success('Page hasbeen updated,  will notify you when finish')
    yield fork(getPageTrackingSaga, { siteId })
    yield callback()
  } else {
    yield put(updatePageTrackingFailure(siteId, response.data))
    yield toast.error('Failed to edit page')
  }
  //
}

function* getReportListSaga({ siteId, pageTrackingId }) {
  const { ok, data: response } = yield call(callApi, getPageTrackingReportMore, siteId, pageTrackingId)
  if (ok) {
    yield put(getPageTrackingReportMoreSuccess(siteId, pageTrackingId, response.data))
  } else {
    yield put(getPageTrackingReportMoreFailure(siteId, pageTrackingId, response.data))
  }
}

export default function* rootSaga() {
  yield debounce(10, REPORT.GET_REPORT, getReportSaga)
  yield debounce(10, SITE.GET_PAGE_QUERY, getPageQuerySaga)
  yield takeLatest(SITE.GET_QUERY_COUNTER, getQueryCounter)
  yield throttle(20, SITE.GET_MISSING_KEYWORD_PAGE, getMentionedPageSaga)
  yield takeLatest(SITE.RESET_QUERY_COUNTER, resetQueryCounter)
  yield debounce(10, SITE.GET_MISSING_KEYWORD_PAGE_LIST, getListPageSaga)
  yield takeLatest(REPORT.GENERATE, generateReportSaga)
  yield debounce(10, SITE.GET_MENTIONED_IN_META, getMentionedInMetaSaga)
  yield throttle(10, SITE.PAGE_TRACKING_LIST, getPageTrackingSaga)
  yield throttle(10, SITE.PAGE_TRACKING_DETAIL, getPageTrackigDetailSaga)
  yield throttle(10, SITE.PAGE_TRACKING_REPORT, getPageTrackingReportSaga)
  yield throttle(10, SITE.PAGE_TRACKING_REPORT_HISTORY, getPageTrackingReportHistorySaga)
  yield takeLatest(SITE.PAGE_TRACKING_CREATE, createTrackingSaga)
  yield takeLatest(SITE.PAGE_TRACKING_UPDATE, updateTrackingSaga)
  yield takeLatest(SITE.PAGE_TRACKING_MORE_REPORT, getReportListSaga)
}
