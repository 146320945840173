import { compareDate } from '@constant/date'
import { createSelector } from 'reselect'
import Router from 'next/router'

export const getActiveSite = createSelector(
  state => state.site.activeId,
  siteId => siteId
)

export const getAllSiteSelector = createSelector(
  state => state.site.all,
  list => [list.data, list.status, list.paginate]
)

export const getSiteDetailSelector = createSelector(
  state => state?.site?.detail[Router.query.id] || {},
  detail => [detail?.data || {}, detail?.status || 'request']
)

export const createSiteSelector = createSelector(
  state => state.site.create,
  create => [create.message, create.status]
)

export const getSiteOverviewSelector = type =>
  createSelector(
    state => state.site.overview[Router.query.id] || {},
    overview => [overview?.data?.[type || 'DATE'] || [], overview.status]
  )

export const getSiteCompareOverviewSelector = type =>
  createSelector(
    state => state.site.compareOverview[Router.query.id] || {},
    overview => [overview?.data?.[type || 'DATE'] || [], overview.status]
  )

export const getSiteFilterSelector = type =>
  createSelector(
    state => state.site.filters,
    filter => [filter.data[type] || filter.data, filter.status]
  )

export const getSiteFilterDetailSelector = createSelector(
  state => state.site.filterDetail,
  filterDetail => [filterDetail.data || [], filterDetail.status]
)

// filters
export const getFilterSelector = (type = null) =>
  createSelector(
    state => state.site.filters,
    filters => {
      const selectedData = type ? filters.data[type] : filters.data
      return [Array.isArray(selectedData) ? selectedData : [], filters.status]
    }
  )

export const getTrackingSelector = createSelector(
  state => state.site.tracking[Router.query.id] || {},
  tracking => [tracking.data || [], tracking.status]
)

export const getTrackerDetailSelector = trackerId =>
  createSelector(
    state => state.site.trackingDetail[trackerId || Router.query.trackingId],
    detail => [detail?.data || [], detail?.status, detail?.filter]
  )

export const getTrackerSummarySelector = trackerId =>
  createSelector(
    state => state.site.trackingSummary[trackerId || Router.query.trackingId],
    detail => [detail?.data, detail?.status, detail?.filter]
  )

export const getAvailabeSiteSelector = createSelector(
  state => state.site.availableSites,
  sites => [sites.data, sites.status]
)

export const getTrackerSelector = createSelector(
  state => state.site.trackingQuery[Router.query.id] ?? {},
  trackingQuery => [trackingQuery.date, trackingQuery.type, trackingQuery.queries, trackingQuery.state]
)

export const getTrackerCompareSelector = createSelector(
  state => state.site.compareQuery[Router.query.id] || {},
  trackingQuery => [trackingQuery.date, trackingQuery.type, trackingQuery.queries]
)

export const getCompareTypeSelector = createSelector(
  state => state.site.trackingQuery[Router.query.id] || {},
  state => state.site.compareQuery[Router.query.id] || {},
  state => state.site.filters?.data || {},
  state => state.country?.data || [],
  (trackingQuery, compareQuery, filters, countries) => {
    if (compareQuery?.type) {
      const target = filters?.types?.find(t => t.value == trackingQuery.type)?.label
      const toCompare = filters?.types?.find(t => t.value == compareQuery.type)?.label

      return [true, 'Type', target || trackingQuery.type, toCompare || compareQuery.type]
    }
    if (compareDate.find(c => c.value == trackingQuery?.date?.type)) {
      let target = null
      let toCompare = null
      if (compareQuery?.date?.type == 'CUSTOM_COMPARE') {
        target = `${trackingQuery?.date?.startDate} to ${trackingQuery?.date?.endDate}`
        toCompare = `${compareQuery?.date?.startDate} to ${compareQuery?.date?.endDate}`
      } else {
        const renderDate = compareDate.find(c => c.value == trackingQuery?.date?.type)?.date?.()
        if (renderDate) {
          target = `${renderDate.target?.startDate} to ${renderDate.target?.endDate}`
          toCompare = `${renderDate.compare?.startDate} to ${renderDate.compare?.endDate}`
        }
      }
      if (target && toCompare) {
        return [true, 'Date', target, toCompare]
      }
    }

    if (compareQuery?.queries?.query?.expression) {
      const trackingFilter = filters?.oprators?.find(t => t.value == trackingQuery?.queries?.query?.operator)?.symbol
      const compareFilter = filters?.oprators?.find(t => t.value == compareQuery?.queries?.query?.operator)?.symbol
      return [
        true,
        'Query',
        `${trackingFilter}${trackingQuery?.queries?.query?.expression}`,
        `${compareFilter}${compareQuery?.queries?.query?.expression}`,
      ]
    }

    if (compareQuery?.queries?.searchAppearance?.expression) {
      const trackingFilter = filters?.oprators?.find(
        t => t.value == trackingQuery?.queries?.searchAppearance?.operator
      )?.symbol
      const compareFilter = filters?.oprators?.find(
        t => t.value == compareQuery?.queries?.searchAppearance?.operator
      )?.symbol

      const target = filters?.searchAppearances?.find(
        t => t.value == trackingQuery?.queries?.searchAppearance?.expression
      )?.label
      const compare = filters?.searchAppearances?.find(
        t => t.value == compareQuery?.queries?.searchAppearance?.expression
      )?.label

      return [true, 'Query', `${trackingFilter}${target}`, `${compareFilter}${compare}`]
    }

    if (compareQuery?.queries?.device?.expression) {
      const target = filters?.devices?.find(t => t.value == trackingQuery?.queries?.device?.expression)?.label
      const compare = filters?.devices?.find(t => t.value == compareQuery?.queries?.device?.expression)?.label

      return [true, 'Query', `${target}`, `${compare}`]
    }

    if (compareQuery?.queries?.page?.expression) {
      const trackingFilter = filters?.oprators?.find(t => t.value == trackingQuery?.queries?.page?.operator)?.symbol
      const compareFilter = filters?.oprators?.find(t => t.value == compareQuery?.queries?.page?.operator)?.symbol
      const targetUrl = new URL(trackingQuery?.queries?.page?.expression || 'https://example.com')?.pathname || ''
      const compareUrl = new URL(compareQuery?.queries?.page?.expression || 'https://example.com')?.pathname || ''
      return [true, 'Query', `${trackingFilter}${targetUrl}`, `${compareFilter}${compareUrl}`]
    }

    if (compareQuery?.queries?.country?.expression) {
      const target = countries?.find(t => t.iso3 == trackingQuery?.queries?.country?.expression)?.name
      const toCompare = countries?.find(t => t.iso3 == compareQuery?.queries?.country?.expression)?.name
      return [true, 'Query', target, toCompare]
    }
    return [null, null, null, null]
  }
)

export const getTableOverviewDataSelector = (type = 'DATE') => {
  return createSelector(
    state => state.site.overview[Router.query.id] || {},
    state => state.site.compareOverview[Router.query.id] || {},
    getCompareTypeSelector,
    (overview, compareOverview, hasCompare) => {
      const sourceOverviewData = overview?.data?.[type || 'DATE'] || []
      const compareOverviewData = compareOverview?.data?.[type || 'DATE'] || []

      let mergedData = []

      for (const so of sourceOverviewData || []) {
        let source = so
        let compare = compareOverviewData.find(
          co => co.keys[0].type == so.keys[0].type && co.keys[0].value == so.keys[0].value
        )

        if (hasCompare[0]) {
          if (source && compare) {
            mergedData.push({
              source,
              compare,
            })
          }
        } else {
          mergedData.push({
            source,
          })
        }
      }

      return [mergedData, ...hasCompare]
    }
  )
}

// site
export const getCreateTestSelector = createSelector(
  state => state.site.seoTestCreate?.[Router.query.id] ?? {},
  test => [test?.data, test?.errors, test?.status]
)

// chaneglog

export const getChangelogSelector = createSelector(
  state => state?.site?.changelog?.[Router.query.id] ?? {},
  log => [log?.data || [], log?.errors, log?.status]
)

export const createLogSelector = createSelector(
  state => state?.site?.changelogCreate?.[Router.query.id],
  changelog => [changelog?.errors || {}, changelog?.status]
)

export const updateLogSelector = createSelector(
  state => state?.site?.changelogUpdate?.[Router.query.id],
  changelog => [changelog?.errors || {}, changelog?.status]
)

export const getMetaSelector = (metas = [], useArray = false, id = null) =>
  createSelector(
    state => state.site.siteMeta[id || Router.query.id],
    detail => {
      let obj = {}

      if (useArray == false) {
        if (metas?.length > 0) {
          for (let mt of metas || []) {
            obj[mt] = detail?.data?.find(m => m?.action_key == mt)
          }
        } else {
          for (const dt of detail?.data || []) {
            obj[dt.action_key] = dt
          }
        }
      } else {
        if (metas?.length > 0) {
          return detail?.data?.filter(mr => metas.indexOf(mr.action_key) >= 0)
        }
        return detail?.data
      }

      return obj
    }
  )
