import { call, debounce, put, select } from 'redux-saga/effects'
import callApi from '@saga/api'
import { getTagSuccess } from '@action/tag'
import { listTag } from '@api/tag'
import { TAG } from '@constant/tag'
import Cookie from '@lib/cookie'
import { getActiveSite } from '@service/selectors/site'

function* listTagSaga({ siteId }) {
  const { ok, data: response } = yield call(callApi, listTag, { id: siteId })
  if (ok) {
    yield put(getTagSuccess(response))
  }
}

export default function* rootSaga() {
  yield debounce(10, TAG.GET, listTagSaga)
}
