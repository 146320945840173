import { TAG } from '@constant/tag'

export const getTag = siteId => ({
  type: TAG.GET,
  siteId,
})

export const getTagFailure = payload => ({
  type: TAG.GET_FAILURE,
  payload,
})

export const getTagSuccess = payload => ({
  type: TAG.GET_SUCCESS,
  payload,
})
